import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
import SimpleBar from "simplebar";

if (document.getElementsByClassName('testimonials-section__slider')[0]) {
  const testimonialsSlidersArr = document.querySelectorAll('.testimonials-section__slider');
  const testimonialsSliderButtonsArr = document.querySelectorAll('.testimonials-section__slider-buttons');
  testimonialsSlidersArr.forEach((sliderEl, sliderIdx) => {
    const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 3;
    }


    let breakpoints = {
      1025: {
        spaceBetween: 24,
      },
      1200: {
        spaceBetween: 30,
      }
    }

    if (sliderEl.classList.contains('careers-type')) {
      breakpoints = {
        1025: {
          spaceBetween: 24,
          slidesPerView: 2,
        },
        1200: {
          spaceBetween: 30,
          slidesPerView: 3,
        }
      }
    }
    if (sliderEl.classList.contains('testimonials-section__slider_sector')) {
      breakpoints = {
        1025: {
          spaceBetween: 30,
          slidesPerView: 2,
        }
      }
    }

    console.log(loop)

    const testimonialsSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 1,
      spaceBetween: 22,
      threshold: 10,
      loop: loop,
      loopedSlides: loopedSlides,
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: testimonialsSliderButtonsArr[sliderIdx].querySelector('.slider-btn.prev'),
        nextEl: testimonialsSliderButtonsArr[sliderIdx].querySelector('.slider-btn.next'),
      },
      breakpoints: breakpoints,
    });

    swiperObserver(testimonialsSwiper);

    setTimeout(function () {
      const testimonialsItemsArr = sliderEl.querySelectorAll('.testimonials-section__item-text');
      console.log(testimonialsItemsArr.length)
      for (let i = 0; i < testimonialsItemsArr.length; i++) {
        new SimpleBar(testimonialsItemsArr[i]);
      }
    }, 100)
  });
}

const blockquoteText = document.querySelector('.big-blockquote-type.content-element');

if (blockquoteText) {
  const textContent = blockquoteText.innerHTML;
  const parts = textContent.split('“');
  const updatedContent = parts.map((part, index) => {
    if (index !== 0) {
      const splitPart = part.split('”');
      if (splitPart.length > 1) {
        return `<span>“</span>${splitPart[0]}<span>”</span>${splitPart[1]}`;
      }
    }
    return part;
  }).join('');
  blockquoteText.innerHTML = updatedContent;
}