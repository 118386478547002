import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let supportSwiperWr = document.querySelectorAll('.support-swiper-wr');
supportSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.slider-btn.next');
    let prevEl = el.querySelector('.slider-btn.prev');
    let slides = el.querySelectorAll('.swiper-slide');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
     let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      spaceBetween: 20,
      slidesPerView: 1,
      speed: 800,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
          650:{
              spaceBetween: 30,
              slidesPerView:3,
          },

        1024: {
          spaceBetween: 30,
            slidesPerView:4,
        },
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      on:{
      afterInit: function(){
          slides.forEach((slide)=>{
              let scrollContainer = slide.querySelector('.support-card-description');
              setBoxMbCounter(scrollContainer, 'p');
          })

        },
        resize: function(){
            slides.forEach((slide)=>{
                let scrollContainer = slide.querySelector('.support-card-description');
                setBoxMbCounter(scrollContainer, 'p');
            })
        }
      }
    });
    swiperObserver(swiper);
  }
});
