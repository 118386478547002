import './utils/vh.utils.js';
/*import './utils/animate-on-view.utils';*/
import './utils/parallax-on-view.utils.js';
import './utils/accordion.utils.js';

import './components/global.component.js';
import './components/header.component.js';
import './components/animations.component.js';
import './components/uploadFile.component.js';
import './components/inputs.component.js';
import './components/job-search.component.js';
import './components/contact.component.js';
import './components/accordions.component.js';
import './components/values.component.js';
import './components/article.component.js';

/*Sliders*/
import './components/logos.component.js';
import './components/roles.component.js';
import './components/specialism.component.js';
import './components/credentials.component.js';
import './components/testimonials.component.js';
import './components/teams.component.js';
import './components/encouragement.component.js';
import './components/classroom.component.js';
import './components/filters.component.js';
import './components/resources.component.js';
import './components/sectors.component.js';
import './components/capabilities.component.js';
import './components/recruit.component.js';
import './components/stages.component.js';
import './components/work.component.js';
import './components/swiper-support.component.js';

/*End Sliders*/

import './libs/countUp.lib.js';

document.addEventListener("DOMContentLoaded", function() {
  fixHrefForLive();

  setTimeout(function () {
    fixHrefForLive();
  }, 1000);
});

function fixHrefForLive() {
  const links = document.querySelectorAll('a[target="_blank"]');

  if(links.length) {
    links.forEach(link => {
      let href = link.href;
      const paramsToRemove = ["?__hstc", "&__hssc", "&__hsfp"];
      paramsToRemove.forEach(param => {
        const index = href.indexOf(param);
        if (index > -1) {
          href = href.substring(0, index);
        }
      });
      link.href = href;
    });
  }
}

if(document.getElementsByClassName('slider-btn')[0]) {
  const sliderButtonsArr = document.querySelectorAll('.slider-btn');
  sliderButtonsArr.forEach(sliderBtn => {
    sliderBtn.addEventListener('click', function() {
      this.blur();

      if (window.matchMedia('(hover: none)').matches) {
        this.classList.add('no-hover');
        setTimeout(() => {
          this.classList.remove('no-hover');
        }, 0);
      }
    });
  })
}
