/*Splitting();*/

if (document.querySelector('[data-splitting]')) {
  const splittingArr = document.querySelectorAll('[data-splitting]');
  splittingArr.forEach((item, i) => {
    const firstChild = item.querySelector('h1, h2, h3');
    const splitting = Splitting({
      target: item,
      by: 'lines',
    });

    function titleBrFunc() {
      const wordElements = item.querySelectorAll('.word');

      let maxLineIndex = -1;
      let firstWordWithMaxLineIndex = null;

      wordElements.forEach(wordElement => {
        const lineIndex = parseInt(wordElement.style.getPropertyValue('--line-index'));

        if (lineIndex > 0 && lineIndex > maxLineIndex) {
          maxLineIndex = lineIndex;
          firstWordWithMaxLineIndex = wordElement;
        }
      });

      if (firstWordWithMaxLineIndex) {
        /*console.log("line-index:", firstWordWithMaxLineIndex.textContent);*/
        firstWordWithMaxLineIndex.classList.add('word-ml');
      }
    }

    document.fonts.ready.then(titleBrFunc);

    /*const styleAttr = item.getAttribute('style');

    if (styleAttr) {
      const stylePairs = styleAttr.split(';');
      for (const pair of stylePairs) {
        const [key, value] = pair.trim().split(':');
        if (key.trim() === '--line-total') {
          const lineTotal = parseInt(value.trim());
          if (lineTotal >= 4) {
            item.querySelectorAll('br').forEach(br => {
              br.style.display = 'none';
            })
          } else if (lineTotal < 4) {
          }
          break;
        }
      }
    }*/

    /*if (firstChild) {
      const firstWord = item.querySelector('.word');
      if (firstWord) {
        /!*const space = document.createTextNode('\u00A0');
        firstWord.insertBefore(space, firstWord.firstChild);
        firstWord.style.marginLeft = '-0.4ch';*!/
        /!*firstWord.textContent = `${ + firstWord.textContent}`;*!/
      } else {
        console.error('.word element not found inside', firstChild.tagName);
      }
    }*/
  });
}

const titleContainer = document.getElementsByClassName("title-container")[0];

if (titleContainer) {
  const changingBoxArr = titleContainer.querySelectorAll(".changing-box");
  /*const time = 35;*/

  function textChangeFunc(changingElemsArr) {
    let count = 0;
    /*const timer = (time / changingElemsArr.length) * 1000;*/
    const timer = 3000;

    function toggleClass() {
      changingElemsArr[count].classList.remove("active");

      count = count < changingElemsArr.length - 1 ? count + 1 : 0;

      changingElemsArr[
          count
          ].parentElement.style.width = `${changingElemsArr[count].offsetWidth}px`;

      changingElemsArr[count].classList.add("active");
    }
    toggleClass();
    setInterval(toggleClass, timer);
  }

  for (let i = 0; i < changingBoxArr.length; i++) {
    const changingElemsArr =
        changingBoxArr[i].querySelectorAll(".changing-elem");
    titleContainer.classList.add("active");
    changingBoxArr[i].style.width = `${changingElemsArr[0].offsetWidth}px`;
    changingElemsArr[0].classList.add("active");

    let timeout;
    switch (i) {
      case 0:
        timeout = 1;
        break;

      case 1:
        timeout = 4;
        break;

      case 2:
        timeout = 3;
        break;

      case 3:
        timeout = 2;
        break;
    }

    setTimeout(function () {
      textChangeFunc(changingElemsArr);
    }, timeout * 2000);
  }
}

window.initTilt = function initTilt() {
  if(document.querySelector('[data-tilt-init]') && window.innerWidth > 1024) {
    const tiltInitItemsArr = document.querySelectorAll('[data-tilt-init]');
    tiltInitItemsArr.forEach(item => {
      if(!item.classList.contains('is-tilt-init')) {
        VanillaTilt.init(item, {
          max: 5,
          speed: 500
        })
        item.classList.add('is-tilt-init');
      }

    })
  }
}
initTilt();

if(document.querySelector('.changing-elem')) {
  const changingElArr = document.querySelectorAll('.changing-elem');

  changingElArr.forEach(el => {
    if(el.textContent === 'Risk and Compliance') {
      el.classList.add('fix-size');
    }
  })
}
