window.autosizeTextarea = function autosizeTextarea() {
  if (document.querySelector('textarea[data-autosize]')) {
    const autosizeTextareaElements = document.querySelectorAll(
      'textarea[data-autosize]'
    );
    autosizeTextareaElements.forEach((textarea) => {
      const startHeight = textarea.offsetHeight;
      textarea.addEventListener('input', autosize);
      function autosize() {
        const el = this;
        const newHeight = el.scrollHeight;
        if (newHeight > startHeight) {
          el.style.minHeight = `${el.scrollHeight}px`;
        } else {
          el.style.minHeight = startHeight;
        }
      }
    });
  }
};
autosizeTextarea();

window.choicesArray = [];

window.initChoices = function initChoices(elementsArr) {
  const choicesDOM = document.querySelector(elementsArr);
  if (choicesDOM) {
    const choicesArr = document.querySelectorAll(`${elementsArr}`);
    for (let i = 0; i < choicesArr.length; i++) {
      console.log('init');
      const parentContainer = choicesArr[i].parentNode;
      /*const list = parentContainer.querySelector(".default-select__list");*/

      const choices = new Choices(choicesArr[i], {
        searchEnabled: false,
        itemSelectText: '',
        placeholder: true,
        searchPlaceholderValue: 'Search',
      });
      choicesArray.push(choices);

      const choicesMultipleElement = parentContainer.querySelector(".choices[data-type='select-multiple']");

      if (choicesMultipleElement) {
        choicesMultipleElement.addEventListener('click', () => {
          if (parentContainer.querySelector('.is-open')) {
            choices.hideDropdown();
          }
        });
      }

      /* New multiselect logic */
      if (choicesArr[i].multiple && choicesArr[i].hasAttribute('data-multiple-list-logic')) {
        let optionName = null;
        let optionValue = null;

        const multiplePlaceholder = parentContainer.querySelector('.choices__list--multiple');

        const list = document.createElement('ul');
        list.className = 'meta-select__list';
        parentContainer.appendChild(list);

        function createListItem(optionName, optionValue) {
          const listItem = document.createElement('li');
          listItem.setAttribute('data-val', optionValue);
          listItem.innerHTML = `${optionName}`;
          listItem.classList.add('meta-select__list-item');
          list.appendChild(listItem);

          listItem.addEventListener('click', () => {
            handleListItemClick(listItem);
          });
        }
        function handleSelectedOptions() {
          list.innerHTML = '';

          const selectedOptions = Array.from(choicesArr[i].selectedOptions);

          if (selectedOptions.length >= 1) {
            list.classList.add('is-visible');
          } else {
            list.classList.remove('is-visible');
          }

          if (selectedOptions.length === 0 && !choicesArr[i].multiple) {
            choices.setChoiceByValue('');
          }

          selectedOptions.forEach(function (option) {
            optionName = option.textContent;
            optionValue = option.value;
            if (optionName !== 'Select') {
              createListItem(optionName, optionValue);
            }
          });

          const listArr = list.querySelectorAll('.meta-select__list-item');
          if (listArr.length === 1) {
            multiplePlaceholder.textContent = optionName;
          } else if (listArr.length >= 2) {
            multiplePlaceholder.textContent = `Selected ${listArr.length} items`;
          } else {
            multiplePlaceholder.textContent = 'Select';
          }
        }

        function handleListItemClick(listItem) {
          const optionValue = listItem.getAttribute('data-val');

          choices.removeActiveItemsByValue(optionValue);
          handleSelectedOptions();
        }

        handleSelectedOptions();

        choicesArr[i].addEventListener('change', function () {
          handleSelectedOptions();
        });

        list.addEventListener('click', function (event) {
          const liElement = event.target.closest('.meta-select__list-item');
          if (liElement) {
            handleListItemClick(liElement);
          }
        });
      }
    }
  }
};

window.initChoices('[data-choices]');

window.getChoice = function getChoice(select) {
  let selectEl = typeof select === 'object' ? select : document.querySelector(select);
  let choicesArr = window.choicesArray;
  let targetChoice;
  if (choicesArr) {
    window.choicesArray.forEach((choices) => {
      let choicesSelect = choices.passedElement.element;
      if (choicesSelect == selectEl) {
        targetChoice = choices;
      }
    });
  }
  return targetChoice;
};

window.resetChoice = function resetChoice(select) {
  let choice = getChoice(select);
  if (choice) {
    let startValue = choice.config.choices[0].value;
    choice.setChoiceByValue(startValue);
  }
};


if (document.querySelector('.meta-input.salary-type')) {
  const salaryInputsArr = document.querySelectorAll(
    '.meta-input.salary-type input'
  );

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  salaryInputsArr.forEach((input) => {
    input.addEventListener('input', (event) => {
      const inputValue = event.target.value.replace(/\D/g, '');
      const formattedValue = formatNumber(inputValue);
      event.target.value = formattedValue;
    });
  });
}

const insightSearchEl = document.getElementsByClassName('insight-search-section__search-title')[0];
if(insightSearchEl) {
  const input = document.querySelector('.insight-search-section__search-input');
  const btn = document.querySelector('.insight-search-section__search-btn');

  btn.addEventListener('click', () => {
    btn.classList.toggle('active');
    input.classList.toggle('active');
  })
}
